import { Box, Flex, Text, chakra } from "@chakra-ui/react";

import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import React from "react";

const Footer = ({ logo }) => {
  return (
    <>
      <Flex
        justify="center"
        align="center"
        direction="column"
        py="20"
        bg="white"
        borderBottom="1px solid black"
      >
        <Box mb="8">
          <GatsbyImage image={logo} />
        </Box>
        <Link to="/impressum">Impressum</Link>
      </Flex>
      <Box py="4" pb={{ base: "24", md: "4" }}>
        <Text textAlign="center">
          ©{new Date().getFullYear()} Drescher GbR | designed&built by{" "}
          <chakra.a color="primary" href="https://clebek.dev">
            Carsten Lebek
          </chakra.a>
        </Text>
      </Box>
    </>
  );
};

export default Footer;
