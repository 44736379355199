import { Flex } from "@chakra-ui/react";
import Logo from "./logo";
import MediumNav from "./mediumNav";
import NavBar from "./navbar";
import React from "react";

const Header = ({ logo, navItems,location }) => {
  return (
    <Flex
      borderBottom="1px solid black"
      justify="space-between"
      position={{ md: "fixed" }}
      top={0}
      w="full"
      bg="white"
      zIndex={30}
    >
      <Logo logo={logo} />
      <NavBar navItems={navItems} location={location} />
      <MediumNav navItems={navItems} location={location} />
    </Flex>
  );
};

export default Header;
