import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    primary: "#284393",
    brand: {
      50: "#e7efff",
      100: "#c1cef3",
      200: "#9aade5",
      300: "#738cd9",
      400: "#4c6ccd",
      500: "#284393",
      600: "#26408c",
      700: "#1a2e65",
      800: "#0d1b3f",
      900: "#01091b",
    },
  },
  fonts: {
    heading: "'Open Sans', Helvetica, Arial, sans-serif",
    body: "'Open Sans', Helvetica, Arial, sans-serif",
  },
  styles: {
    global: {
      body: {
        fontSize: { base: "1rem", lg: "1.15rem" },
        bg: "gray.100",
        scrollBehavior: "smooth",
        overflowY: "scroll",
        overflowX: "hidden",
        hyphens: "auto",
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        textTransform: "uppercase",
      },
    },
    Button: {
      baseStyle: {
        rounded: "none",
        border: "1px solid black",
      },
    },
  },
};

export default extendTheme(theme);
