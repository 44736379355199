import { graphql, useStaticQuery } from "gatsby";

import Footer from "@components/footer";
import Header from "@components/header";
import React from "react";

const Layout = ({ children, location }) => {
  console.log("LOCATION ", location);
  const data = useStaticQuery(graphql`
    {
      sanityHeaderMenu {
        menuItems {
          content
          href
        }
      }
      sanitySiteSettings {
        Logo {
          asset {
            gatsbyImageData(layout: CONSTRAINED, height: 60, width: 83, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  console.log(data);

  const {
    sanityHeaderMenu: { menuItems },
    sanitySiteSettings: {
      Logo: {
        asset: { gatsbyImageData: logo },
      },
    },
  } = data;

  return (
    <div>
      <Header logo={logo} navItems={menuItems} location={location} />
      {children}
      <Footer logo={logo} />
    </div>
  );
};

export default Layout;
