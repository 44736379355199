import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import React from "react";
import { Link as SmoothLink } from "react-scroll";

const NavItem = ({ href, content, onClick, location }) => {
  if (location.pathname === "/" && href.charAt(1) === "#") {
    return (
      <SmoothLink to={href.slice(2)} smooth={true} spy={true} onClick={onClick}>
        <Box p="4" cursor="pointer">
          {content}
        </Box>
      </SmoothLink>
    );
  }
  return (
    <Link to={href} style={{ height: "100%" }} onClick={onClick}>
      <Box p="4">{content}</Box>
    </Link>
  );
};

const MediumNav = ({ navItems, location }) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const size = useBreakpointValue({ base: "base", md: "md" });

  return (
    <Box
      display={{ base: "block", md: "block", xl: "none" }}
      position={{ base: "fixed", md: "unset" }}
      bottom={0}
      left={0}
      w={{ base: "full", md: "fit-content" }}
      zIndex="30"
      p={{ base: "4", md: "0" }}
      bg={{ base: "white", md: "transparent" }}
      borderTop={{ base: "1px solid black", md: "none" }}
    >
      {size === "md" ? (
        <IconButton
          icon={<HamburgerIcon />}
          colorScheme="brand"
          h="full"
          w={{ base: "full", md: "32" }}
          fontSize="4xl"
          borderY="none"
          onClick={onToggle}
          py={{ base: "2", md: "none" }}
        >
          {size !== "md" && "Menü"}
        </IconButton>
      ) : (
        <Button
          leftIcon={<HamburgerIcon />}
          colorScheme="brand"
          h="full"
          w={{ base: "full", md: "32" }}
          fontSize="2xl"
          borderY="none"
          onClick={onToggle}
          py={{ base: "2", md: "none" }}
        >
          Menü
        </Button>
      )}
      <Drawer isOpen={isOpen} placement={size === "base" ? "bottom" : "right"} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menü</DrawerHeader>
          <DrawerBody>
            <NavItem href="/#" content="Home" onClick={onToggle} location={location} />
            {navItems.map((navItem, index) => (
              <NavItem
                key={index}
                href={navItem.href}
                content={navItem.content}
                onClick={onToggle}
                location={location}
              />
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MediumNav;
