import { Box, Flex } from "@chakra-ui/react";

import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import React from "react";

const Logo = ({ logo }) => {
  console.log(logo);
  return (
    <Flex borderRight="1px solid black">
      <Box h="full" w="4" bg="primary" display="inline-block" />
      <Link to="/" style={{ display: "inline-block" }} aria-label="Home">
        <Box px="12" py="2">
          <GatsbyImage image={logo} />
        </Box>
      </Link>
    </Flex>
  );
};

export default Logo;
