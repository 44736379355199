import { Flex } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { Link as SmoothLink } from "react-scroll";

const NavItem = ({ href, content, location }) => {
  if (location.pathname === "/" && href.charAt(1) === "#") {
    return (
      <SmoothLink to={href.slice(2)} smooth={true} spy={true} offset={-70}>
        <Flex
          borderLeft="1px solid black"
          justify="center"
          align="center"
          px={{ lg: 24 }}
          py="4"
          h="full"
          cursor="pointer"
        >
          {content}
        </Flex>
      </SmoothLink>
    );
  }
  return (
    <Link to={href} style={{ height: "100%" }}>
      <Flex
        borderLeft="1px solid black"
        justify="center"
        align="center"
        px={{ lg: 24 }}
        py="4"
        h="full"
      >
        {content}
      </Flex>
    </Link>
  );
};

const NavBar = ({ navItems, location }) => {
  return (
    <Flex
      align="stretch"
      fontWeight="400"
      fontSize="xl"
      display={{ base: "none", xl: "flex" }}
      style={{ hyphens: "none" }}
    >
      {navItems.map((navItem, index) => (
        <NavItem key={index} href={navItem.href} content={navItem.content} location={location} />
      ))}
    </Flex>
  );
};

export default NavBar;
